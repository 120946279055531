import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PaymentMethods from '../components/PaymentMethods'
import { FiInfo, FiMinus, FiPlus, FiCalendar, FiCreditCard, FiFile, FiPhoneIncoming, FiX } from "react-icons/fi";
import { Link } from 'react-router-dom';
import UserSideBar from './UserSideBar';
import { Routes, Route, Router, Outlet, useParams } from 'react-router-dom';
import {Protected} from '../../Protected';
import FeedBack from './FeedBack';
import MobileTV from '../mobile_tv_component/MobileTV';
import UserDashborad from './UserDashborad';
import ErrorPage from '../layout_components/ErrorPage';
import PaymentHistory from './PaymentHistory';

export default function UserPage() {
  const [state, setState] = useState([])


  useEffect(() => {
    axios({method: 'GET', url: 'https://api.arpinet.am/api/user/data/'})
    .then(res => {
      setState(res.data.data)
    });
  }, [])


  return (
    <div className="col-sm-12 p-0 pt-5 pb-5">
      <div className="container">
        <div className="row">

          <div className='col-lg-4 col-md-5 col-12 position-md-sticky p-md-3 p-0'>
            <div className="col-12 stretch-card mb-3 mt-3 p-0 position-md-sticky">
              <UserSideBar user={state?.abonent_name + ' ' + state?.abonent_surname} uid={state?.uid} agreement={state?.agreements?.length} mobile={state?.mobile} email={state?.email}/>
            </div>
          </div>

          <div className='col-lg-8 col-md-7 col-12 mt-3 p-md-3'>

          <Routes>
  <Route path="dashboard" element={<UserDashborad data={state} />} />
  <Route path="payment_history" element={<PaymentHistory />} />
  <Route path="feedback" element={<FeedBack user={state?.abonent_name + ' ' + state?.abonent_surname} />} />
  <Route path="mobile" element={<MobileTV />} />
  <Route path="*" element={<ErrorPage />} />
</Routes>
          </div>
      </div>
          <PaymentMethods/>
    </div>
  </div>
  )
}
