import React from 'react'
import { useTranslation } from 'react-i18next';
import speedtest from "../../assets/images/speedtest.svg";


export default function SpeedCheckComponent() {
  const { t } = useTranslation();
  return (
    <div className="col-xs-12 p-0 pt-5">
    <div className="container mb-5">
      <div className="row pb-2 mb-2">
        <a className="col-12 d-lg-flex d-sm-block justify-content-between align-items-center" target="blank" href="http://arpinet.speedtestcustom.com/">
          <div className="col-lg-3 col-md-3 col-sm-4 mx-auto">
            <img className="mx-auto col-sm-12 " src={speedtest} alt="speedtest"/>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 text-center">
            <div className="col-12 gradient-orange-bg gradient-text pb-2 text-uppercase fw-bolder h2">
                {t('check_internet_headline')}
              </div>
              <div className="col-12 gradient-green-bg gradient-text text-uppercase h6">
              {t('check_internet_subline')}
              </div>
          </div>
        </a>
      </div>
    </div>
    <div className="sep-line"></div>
  </div>
  )
}
