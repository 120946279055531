import React, { useState } from 'react';
import { FiInfo, FiMinus, FiPlus, FiCalendar, FiCreditCard, FiBarChart } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, makePayment } from '../../static/api';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


// https://arpinet.am/user_account/dashboard?paid=true
export default function UserDashboard(props) {
  const [collapse, setCollapse] = useState(true);
  const [colId, setColId] = useState('');
  const [state, setState] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paid = queryParams.get('paid');
  const navigate = useNavigate();
  const lng = localStorage.getItem('i18nextLng')

  const handleModalOpen = (agreement) => {
    setSelectedAgreement(agreement);
    setState(agreement?.tariffs[0]?.rent - agreement?.balance); // Initialize input field value
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedAgreement(null);
    setState(''); // Reset the input field value
  };

  const CollapseCard = (e) => {
    if (colId === e) {
      setCollapse(true);
      setColId('');
    } else {
      setCollapse(false);
      setColId('');
    }
  };

  const handlePaymentSubmit = async () => {
    const requestData = {
      agrm_id: selectedAgreement?.agrm_id,
      amount: state,
      // lang: lng.substring(0, 2),
      agrm_name: selectedAgreement?.agrm_num,
    };

    try {
      const response = await axios.post(`${baseURL}${makePayment}`, requestData);
      const redirect = response.data?.redirect_url;

      if (redirect) {
        window.open(redirect, '_blank');
      }
    } catch (error) {
    }
  };



  return (
    <div>

{paid === 'true' && (
        <div className="alert alert-success b-radius b-shadow p-5 text-center">
          <h1>{t('_user_page_online_payment_successfull_thank_you')}</h1>
          <div className='fs-5'>{t('_user_page_online_payment_successfull_payment_success')}</div>
          <Link to="/user_account/dashboard" className="net-btn green-bg green-shadow py-3 px-3 mt-5">
            {t('_error_page_link')}
          </Link>
        </div>
      )}
      {paid === 'false' && (
        <div className="alert alert-danger b-radius b-shadow p-5 text-center">
            <h1>{t('_user_page_online_payment_not_successfull_headline')}</h1>
            <div className='fs-5'>{t('_user_page_online_payment_not_successfull_payment_false')}<br/>{t('_user_page_online_payment_not_successfull_help_text')}</div>
          <Link to="/user_account/dashboard" className="net-btn green-bg green-shadow py-3 px-3 mt-5">
            {t('_error_page_link')}
          </Link>
        </div>
      )}
      {!paid && (
        <div >
      {props?.data?.agreements?.map((a, i) => (
        <div key={i} className="row mb-3">
          <div className="col-sm-12 p-0">
            <div className="card border-0 b-radius b-shadow p-4 text text-uppercase">
              <div className="col-sm-12 d-inline-flex align-items-center justify-content-between px-3">
                <div className="align-items-center d-flex">
                  <a
                    className="btn h6 mb-0"
                    role="button"
                    data-bs-toggle="collapse"
                    href={'#' + 'collapse' + `${a.agrm_id}`}
                    aria-expanded="true"
                    onClick={(e) => {
                      CollapseCard(a.agrm_id);
                      setColId(a.agrm_id);
                    }}
                  >
                    {collapse && colId === a.agrm_id ? <FiPlus /> : <FiMinus />}
                  </a>
                  <div
                    className={
                      a.balance <= 1000
                        ? 'fw-bold mb-0 d-inline-flex align-items-center text-danger text-uppercase'
                        : 'fw-bold mb-0 d-inline-flex align-items-center text-uppercase'
                    }
                  >
                    <FiInfo className="me-3" />
                    <span>
                      {t('_user_page_agreements_')} N {a.agrm_num}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  className="net-btn green-bg green-shadow py-2 px-4"
                  onClick={() => handleModalOpen(a)}
                >
                  {t('_user_page_online_payment_btn')}
                </button>
              </div>

              <div className="collapse show" id={'collapse' + `${a.agrm_id}`}>
                <hr className="mt-1" />

                <div className="col-12 px-3 pt-2">
                  <div className="col-sm-12 text-muted">{t('_user_page_information')}</div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="col-sm-12 m-2 d-flex align-items-center">
                        <span className="badge badge-success me-3">
                          <FiCalendar />
                        </span>
                        {t('_user_page_subscribtion_date')} {a.create_date}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 ">
                      <div className="col-sm-12 m-2 d-flex align-items-center">
                        <span
                          className={
                            a.balance <= 1000
                              ? 'badge badge-danger me-3'
                              : 'badge badge-success me-3'
                          }
                        >
                          <FiCreditCard />
                        </span>
                        <span
                          className={
                            a.balance <= 1000 ? 'text-danger me-3' : null
                          }
                        >
                          {t('_user_page_balance')} {parseInt(a.balance, 10)}{' '}
                          <span className="small">Դ․</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <Link
                        to={{
                          pathname: '/user_account/payment_history',
                        }}
                        state={{ agrm_id: a.agrm_id }}
                        className="col-sm-12 m-2 d-flex align-items-center text-dark opacity-75"
                      >
                        <span className="badge badge-success me-3">
                          <FiBarChart />
                        </span>
                        {t('_user_page_online_payment_history_title')}
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-12 text-muted mt-4">
                    {t('_user_page_services')}
                  </div>

                  <div className="row">
                    {a.tariffs.map((t, i) => (
                      <div key={i} className="col-sm-12 d-flex m-2">
                        <span className="badge badge-warning me-3">
                          <FiInfo />
                        </span>
                        <span className="fst-italic">
                          {t.name} - {t.rent} Դ․
                        </span>
                      </div>
                    ))}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Bootstrap Modal */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t('_user_page_online_payment_btn')}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleModalClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="text-center fw-bold">
                  {t('_user_page_online_payment_form_seperated_lines_01')} N{' '}
                  <span className="text-danger fw-bold">
                    {selectedAgreement?.agrm_num}
                  </span>{' '}
                  {t('_user_page_online_payment_form_seperated_lines_02')}{' '}
                  <span className="text-danger fw-bold">
                    {selectedAgreement?.tariffs[0]?.rent}
                  </span>
                  {t('_user_page_online_payment_form_seperated_lines_03')}{' '}
                  <span className="text-danger fw-bold">
                    {selectedAgreement?.balance}
                  </span>{' '}
                  {t('_user_page_online_payment_form_seperated_lines_04')}{' '}
                  <span className="text-danger fw-bold">
                    {selectedAgreement?.tariffs[0]?.rent -
                      selectedAgreement?.balance}
                  </span>
                </div>
                <div className="mt-5 mb-3">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control mb-4 b-radius b-shadow px-4 py-3"
                      placeholder="Enter amount"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className="text-danger">
                    {t('_user_page_online_payment_form_minimum_amount')}
                  </div>
                </div>
              </div>
              <hr/>
              <div className="justify-content-center">
                <button
                  type="button"
                  className="net-btn orange-bg orange-shadow py-3 px-3 m-2"
                  onClick={handleModalClose}
                >
                  {t('_cancel')}
                </button>
                <button
                  type="button"
                  className="net-btn green-bg green-shadow py-3 px-3 m-2"
                  onClick={handlePaymentSubmit}
                >
                  {t('_user_page_online_payment_btn')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
              </div>
      )}
    </div>
  );
}
