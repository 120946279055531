import { alt_mobile_tv } from '../../static/Static'
import American_Express_logo from '../../assets/images/mobile-tv/credit_cards/American_Express_logo.png'
import arca_logo from '../../assets/images/mobile-tv/credit_cards/arca_logo.png'
import mastercard_logo from '../../assets/images/mobile-tv/credit_cards/mastercard_logo.png'
import visa_logo from '../../assets/images/mobile-tv/credit_cards/visa_logo.png'

export default function PaymentOptions(props) {
    return (
        <div className="col-sm-12 col-xs-12 text-muted pb-3 mb-3">
            <h4 className="w-100 text-center pt-3 pb-5">{props.t('_tariff_payment_types')}</h4>
            <div className="col-md-7 mx-auto text-center justify-content-center">
                <img className="col-sm-2 col-xs-2 p-2 b-radius" src={arca_logo} alt={alt_mobile_tv} />
                <img className="col-sm-2 col-xs-2 p-2 b-radius" src={mastercard_logo} alt={alt_mobile_tv} />
                <img className="col-sm-2 col-xs-2 p-2 b-radius" src={American_Express_logo} alt={alt_mobile_tv} />
                <img className="col-sm-2 col-xs-2 p-2 b-radius" src={visa_logo} alt={alt_mobile_tv} />
            </div>
        </div>
    )
}
