import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'
import { I18nextProvider } from 'react-i18next';

import i18n from './i18next';
import App from './App'
import LoaderMain from './pages/LoaderMain'
import 'bootstrap'
import 'popper.js'
import 'animate.css/animate.css'
import './assets/css/bootstrap.css'
import './assets/css/styles.css'

const app = (
  <Suspense fallback={<LoaderMain />}>
    <BrowserRouter>
      <ScrollToTop />
      <I18nextProvider i18n={i18n} defaultNS={'translation'}>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </I18nextProvider>
    </BrowserRouter>
  </Suspense>
);

ReactDOM.render(app, document.querySelector("#arpinet"));
