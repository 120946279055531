import React, {useState} from 'react'
import axios from 'axios'
import {baseURL, feedback} from '../../static/api'
import { Link } from 'react-router-dom';
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";


export default function FeedBack(props) {
    // console.log(props);
    const [ choice, setChoice ] = useState('')
    const [ choiceText, setChoiceText ] = useState('հայտ')
    const [ text, setText ] = useState('')
    const [ form, setFrom ] = useState(true)

    function SendData(e){
        e.preventDefault(e)
        if(text && choice){
            axios({
                method: 'POST',
                url: baseURL+feedback,
                data: {"message": text, "type": choice}
              }).then(res => {
                    setFrom(false)
                    setTimeout(() => {
                        window.location.href = '/user_account/dashboard'
                    }, 5000);
              });
        }
    }
    function CencelSubmit(e){
        setChoice('');
        setChoiceText()
        setText('')
    }
  return (

    <div className="row mb-3">
        <div className="col-sm-12 p-0">
            <div className="card border-0 b-radius b-shadow p-4 text text-uppercase">

                <div className="col-12 px-3 pt-2">
                    <div className='text-center'>
                        Հարգելի <span className='d-inline-flex gradient-green-bg gradient-text fw-bold'>{props?.user}</span> «ԱՐՓԻՆԵՏ» ՍՊ ընկերությունը կարևորում է իրեն վստահած բաժանորդների կարծիքը և առաջարկները: Եթե ունեք առաջարկ կամ բողոք մատուցվող ծառայությունների վերաբերյալ և/կամ սպասարկման վերաբերյալ խնդրում ենք ներկայացնել այն:
                    </div>

                    <div className="row">
                        { form ?
                        <form className="col-md-12" onSubmit={e=> {SendData(e); e.preventDefault(e)}}>
                            <div className="row my-4">
                                <div className="col-12 text-center">
                                    <div className="form-check form-check-inline d-inline-flex align-items-center mx-4">
                                        <input className="form-check-input" type="radio" name="feedback" checked={choice === '0'} value={choice} id="0" onChange={() => {setChoice('0'); setChoiceText('առաջարկը')}}/>
                                        <label className="form-check-label mt-2 ms-3" for="0">Առաջարկ</label>
                                    </div>
                                    <div className="form-check form-check-inline d-inline-flex align-items-center mx-4">
                                        <input className="form-check-input" type="radio" name="feedback" checked={choice === '1'} value={choice} id="1" onChange={() => {setChoice('1'); setChoiceText('դժգոհությունը')}}/>
                                        <label className="form-check-label mt-2 ms-3" for="1">Դժգոհություն</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row  my-4">
                                <div className="col-lg-8 col-md-9 col-sm-10 col-12 text-center mx-auto">
                                    <textarea type='text' rows="6" style={{resize:'none'}} className='form-control b-radius b-shadow mt-3 text-muted'
                                        value={text}
                                        onChange={e => setText(e.target.value)}
                                        >
                                    </textarea>
                                </div>
                            </div>

                            <div className="row  my-4">
                                <div className="col-lg-8 col-md-9 col-sm-10 col-12 text-center mx-auto mt-4">
                                <Link to="/user_account/dashboard" className="net-btn orange-bg orange-shadow py-2 px-5 m-1" onClick={e => CencelSubmit(e)}><FiArrowLeft className="me-2"/> Չեղարկել</Link>
                                <button disabled={text && choice ? null : 'disabled'}
                                className={choice=='0' ? "net-btn green-bg green-shadow py-2 px-5 m-1" :"net-btn red-bg red-shadow py-2 px-5 m-1" }
                                onSubmit={e => SendData(e)}
                                >
                                Ուղարկել {choiceText} <FiArrowRight className="ms-2"/>
                            </button>
                                </div>
                            </div>
                        </form>
                        :
                        <div className=''>
                            <div className="text-center pt-5 pb-5 mt-3">
                                <p className="green-text text-uppercase h4 pb-4">Շնորհակալություն</p>
                                <p className="text-muted">Հարգելի {props?.user} {choice === '0' ? 'Ձեր առաջարկն ընդունված է: Մեր մասնագետները կդիտարկեն այն:' : 'սիրով կդիտարկենք Ձեր կողմից նշված հայտը:'}</p>
                            </div>
                        </div>
                         }

                    </div>
                </div>

            </div>
        </div>
    </div>


)
}