import React from 'react'
import { useTranslation } from 'react-i18next';
import { FiLogIn, FiArrowRight  } from 'react-icons/fi';
import hy_lg_arpicam from '../../assets/images/slider/hy_lg_arpicam.jpg' 
import en_lg_arpicam from '../../assets/images/slider/en_lg_arpicam.jpg' 
import ru_lg_arpicam from '../../assets/images/slider/ru_lg_arpicam.jpg' 
import hy_sm_arpicam from '../../assets/images/slider/hy_sm_arpicam.jpg' 
import ru_sm_arpicam from '../../assets/images/slider/ru_sm_arpicam.jpg' 
import en_sm_arpicam from '../../assets/images/slider/en_sm_arpicam.jpg' 

export default function HomeArpicam() {
  const lng = localStorage.getItem('i18nextLng')
  const { t } = useTranslation();
  return (
    <div className="col-xs-12 p-0">
      <div className="container pb-5 p-0">
      <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"} 
          src={
              lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_arpicam : hy_lg_arpicam) :en_lg_arpicam 
              } />
      <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
          src={
              lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_arpicam : hy_sm_arpicam) :en_sm_arpicam 
              } />   
          <div className="py-3">
            <a className="net-btn green-bg green-shadow py-3 px-4 m-2" target="blank" href="https://watcher.arpinet.am/">{t('_arpicam_login')}<FiLogIn className='ms-2'/> </a>
            <a className="net-btn orange-bg orange-shadow py-3 px-4 m-2" target="blank" href="/arpicam">{t('_arpicam_more')} <FiArrowRight className='ms-2'/></a>
          </div>
      </div>
      <div className="sep-line"></div>
    </div>
  )
}