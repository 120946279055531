import useFetch from '../hooks/useFetch';
import { FiFile } from 'react-icons/fi';
import MetaTags from './ui-elements/MetaTags';

export default function TearmsPage(props) {
    const { data } = useFetch(props?.url)
    return (
        <>
            <MetaTags title={props?.t('_terms_and_conditions_title')} />
            {data ?
                <>
                    <section className="col-12 p-0 py-3">
                        <div className="container pt-5">
                            <h3 className="gradient-orange-bg gradient-text text-center text-uppercase">{props?.t('_terms_and_conditions_title')}</h3>
                            <h6 className="text-muted text-center pb-5">{props?.t('_terms_and_conditions_description')}</h6>
                            <div className="card b-radius b-shadow border-0 my-2 p-5">
                                {data[0]?.term_files?.slice(0, 1).map((term, i) => {
                                    return (
                                        <a key={i} className="card flex-row b-radius b-shadow px-3 py-3 my-2 border-0 text-muted" target="blank" href={term.file}>
                                            <FiFile className='me-2 green-text' />
                                            <span className="me-2 text-">{props?.t('_terms_and_conditions_file_name')} {term.description}</span><br />
                                            <span className="mr-2 gradient-green-bg gradient-text">{props?.t('_terms_and_conditions_download')}</span>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                    <section className="col-12 p-0 py-3">
                        <div className="container ">
                            <div className="card b-radius b-shadow border-0 my-2 p-5">
                                <h6 className="text-muted text-center">- ԱՐԽԻՎ -</h6>
                                {data[0]?.term_files?.slice(1, 999999).map((term, i) => {
                                    return (
                                        <a key={i} className="card flex-row b-radius b-shadow px-3 py-3 my-2 border-0 text-muted" target="blank" href={term.file}>
                                            <FiFile className='me-2 green-text' />
                                            <span className="me-2 text-">{props?.t('_terms_and_conditions_file_name')} {term.description}</span><br />
                                            <span className="me-2 gradient-green-bg gradient-text">{props?.t('_terms_and_conditions_download')}</span>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </>
                :
                <>
                    <div className="col-12 py-3">
                        <div className="container pb-5">
                            <div className="card b-radius b-shadow border-0 mb-5 p-5">
                                <div className="p-5">
                                    {props?.load}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}
