import {useState, useEffect} from "react";
import {Routes, Route} from "react-router-dom";
import {
	baseURL,
	aboutURL,
	termsURL,
	itemURL,
	adressessURL,
	tariffsURL,
	locationsURL,
	reportsURL,
} from "./static/api";
import {useTranslation} from "react-i18next";
import axios from "axios";

import Loading from "./pages/Loader";

import AboutCompany from "./pages/about_company/AboutCompany";
import VoipService from "./pages/voip_service/VoipService";
import ArpicamService from "./pages/arpicam_service/ArpicamService";
import NavBar from "./pages/layout_components/NavBar";
import ErrorPage from "./pages/layout_components/ErrorPage";

import HomeComponent from "./pages/HomeComponent";
import InternetPage from "./pages/InternetPage";
import TVpage from "./pages/TVpage";
import MobileTV from "./pages/mobile_tv_component/MobileTV";
import ReportsPage from "./pages/ReportsPage";
import ContactPage from "./pages/contacts_page/ContactPage";
import TermsPage from "./pages/TermsPage";
import CallRequest from "./pages/CallRequest";
import OnlineCV from "./pages/OnlineCV";
import AllPosts from "./pages/AllPosts";
import PostItem from "./pages/components/PostItem";
import Notifications from "./pages/Notifications";
import ConnectionStatus from "./pages/ui-elements/ConnectionStatus";

import UserLogin from "./pages/user_page/UserLogin";
import UserPage from "./pages/user_page/UserPage_new";
import UserLogout from "./pages/user_page/UserLogout";
import {Protected} from "./Protected";
import CareerPage from "./pages/CareerPage";
import CareerItem from "./pages/CareerItem";
import AdditionalRequsetForm from "./pages/AdditionalRequsetForm";
import Footer from "./pages/layout_components/Footer";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";

export default function App() {
	const {t} = useTranslation();
	const [status, setStatus] = useState();
	const [lng, setLng] = useState(
		localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "hy",
	);

	useEffect(() => {
		const nextLng = localStorage.getItem("i18nextLng");
		setLng(nextLng);
	}, [lng]);

	axios.interceptors.request.use(
		async config => {
			const token = localStorage.getItem("token");
			config.headers.common["Accept-Language"] = lng.substring(0, 2);
			if (token) config.headers.Authorization = `Token ${token}`;
			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (navigator.onLine) {
			setStatus(true);
		} else {
			setStatus(false);
		}
	});

	window.addEventListener("offline", () => setStatus(false));
	window.addEventListener("online", () => setStatus(true));

	return (
		<>
			<div className="content-wrapper pt-4 pb-5 mb-5">
				<div className="col-sm-12 col-xs-12 p-0">
					<div className="row m-0 p-0 mt-5">
						<NavBar />
						{status ? (
							<Routes>
								<Route exact path={"/"} element={<HomeComponent />} />
								<Route
									exact
									path="/internet"
									element={
										<InternetPage
											t={t}
											url={baseURL + tariffsURL}
											locations={baseURL + locationsURL}
											lng={lng}
										/>
									}
								/>
								<Route exact path="/tv" element={<TVpage />} />
								<Route exact path="/mobile-tv" element={<MobileTV t={t} />} />
								<Route exact path="/arpicam" element={<ArpicamService />} />
								<Route exact path="/phone" element={<VoipService />} />
								<Route
									exact
									path="/about_us"
									element={<AboutCompany t={t} url={baseURL + aboutURL} load={Loading} />}
								/>
								<Route exact path="/news" element={<AllPosts />} />
								<Route
									exact
									path="/news/:id"
									element={<PostItem t={t} url={baseURL + itemURL} load={Loading} />}
								/>
								<Route
									exact
									path="/terms"
									element={<TermsPage t={t} url={baseURL + termsURL} load={Loading} />}
								/>
								<Route exact path="/online-cv" element={<OnlineCV />} />
								<Route exact path="/contacts" element={<ContactPage t={t} />} />
								<Route
									exact
									path="/report"
									element={
										<ReportsPage
											t={t}
											terms_url={baseURL + termsURL}
											report_url={baseURL + reportsURL}
										/>
									}
								/>
								<Route exact path="/notifications" element={<Notifications />} />
								<Route exact path="/career" element={<CareerPage />} />
								<Route exact path="/career/:id" element={<CareerItem />} />
								{/* <Route exact path='/free_form' element={<AdditionalRequsetForm />} /> */}
								<Route exact path="/privacy_and_policy" element={<PrivacyAndPolicy />} />

								<Route element={<Protected />}>
									<Route exact path="/login" element={<UserLogin />} />
									<Route exact path="/logout" element={<UserLogout />} />
									<Route path="/user_account/*" element={<UserPage />} />
									{/* <Route exact path='/feedback' element={<FeedBack/>}/> */}
								</Route>
								<Route path="*" element={<ErrorPage t={t} />} />
							</Routes>
						) : (
							<ConnectionStatus t={t} />
						)}
					</div>
				</div>
			</div>
			<Footer t={t} url={baseURL + adressessURL} />
			{status ? <CallRequest /> : null}
		</>
	);
}
