import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { baseURL, itemURL } from '../../static/api'
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet'
import { FiCalendar } from 'react-icons/fi';
import { FaShareAlt } from 'react-icons/fa';
import SocialSaheIcons from '../ui-elements/SocialSaheIcons'
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function PostItem(props) {
    const { t } = useTranslation();
    const { id } = useParams();
    const lng = localStorage.getItem('i18nextLng')
    const [state, setState] = useState({
        posts: [],
        images: []
    })
    const [index, setIndex] = useState(-1);
    const currentImage = state.images[index];
    const nextIndex = (index + 1) % state.images.length;
    const nextImage = state.images[nextIndex] || currentImage;
    const prevIndex = (index + state.images.length - 1) % state.images.length;
    const prevImage = state.images[prevIndex] || currentImage;
    const handleClick = (index: number, item: CustomImage) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);


    useEffect(() => {
        axios.get(baseURL + itemURL + `?id=${id}`, { headers: { "Accept-Language": lng.substring(0, 2) } }).then((res) => {
            setState({
                posts: res.data.results[0],
                images: res.data.results[0].post_images.map((item) => {
                    return ({
                        'src': item.image,
                        'original': item.image_thumbnail,
                        'width': 450,
                        'height': 270,
                    })
                }

                )
            });
        });
    }, [lng]);



    console.log(currentImage);
    return (
        <>
            {state ?
                <Helmet>
                    <title>{state?.posts.title}</title>
                    <meta property="og:image" content={state?.posts.image}></meta>
                    <meta property="og:site_name" content={state?.posts.title}></meta>
                    <meta property="og:type" content="article"></meta>
                    <meta property="og:url" content={window.location.href}></meta>
                    <meta property="og:description" content={state?.posts.description}></meta>
                    <link rel="stylesheet" href="/gallery/css/unite-gallery.css" />
                </Helmet>
                : null}


            <div className="col-12 mt-3 pb-5 p-0">
                <div className="container">
                    <div className="card b-radius b-shadow mt-5 mb-5 p-5">
                        <h3 className="gradient-orange-bg gradient-text text-uppercase pb-2">{state.posts.title}</h3>
                        <p className="text-muted d-flex align-items-center"><FiCalendar className="me-2" />{state.posts.date}</p>
                        <hr />
                        <div className="text text-justify pt-2 pb-5 white-space">{state.posts.description}</div>

                        <Gallery
                            images={state?.images}
                            onClick={handleClick}
                            enableImageSelection={false}
                        />
                        {!!currentImage && (
                            <Lightbox
                                mainSrc={currentImage.src}
                                imageTitle={state.posts.title}
                                mainSrcThumbnail={currentImage.original}
                                nextSrc={nextImage.src}
                                nextSrcThumbnail={nextImage.original}
                                prevSrc={prevImage.src}
                                prevSrcThumbnail={prevImage.original}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                            />
                        )}

                        <div className="share col-12 pt-5 text-center">
                            <span className="gradient-green-bg gradient-text"><FaShareAlt className="me-2" />{t('_share')}</span>
                            <SocialSaheIcons
                                url={window.location.href}
                                title={state.posts.title}
                                description={state.posts.description}
                                image={state.posts.image}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
