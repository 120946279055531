import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { baseURL, uidURL, signinURL, signinConfirmURL } from '../../static/api'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/mobile'
import InputMask from 'react-input-mask';
import small_logo from '../../assets/images/logo-small.jpg'
import { alt_main } from "../../static/Static";
import MetaTags from "../ui-elements/MetaTags";

import { FiUser, FiPhone, FiUserCheck, FiArrowRight, FiArrowLeft, FiPhoneIncoming } from "react-icons/fi";

export default function Login(props) {
  const { t } = useTranslation();
  const intervalRef = useRef();
  const inputRef = useRef(null);
  const [userIdForm, setuserIdForm] = useState(true);
  const [userNumberForm, setuserNumberForm] = useState(false);
  const [userCodeForm, setuserCodeForm] = useState(false);
  const [userID, setuserID] = useState('');
  const [userMobile, setuserMobile] = useState('');
  const [userNumber, setuserNumber] = useState('');
  const [userConfiramtion, setuserConfiramtion] = useState('*');
  const [errorCode, seterrorCode] = useState('');
  const [mobButton, setMobButton] = useState(false)
  const [progress, setProgress] = useState(0)
  const [back, setBack] = useState(null)
  const [callError, setCallError] = useState(null)


  const allowedCodes = [
     '10', '11', '12', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
    '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
    '60', '61', '62', '63', '64', '65', '66', '67', '68', '69',
    // '70', '71', '72', '73', '74', '75', '76', '77', '78', '79',
    // '80', '81', '82', '83', '84', '85', '86', '87', '88', '89',
    '90', '91', '92', '93', '94', '95', '96', '97', '98', '99'
  ];


  const isValidArmenianNumber = (number) => {
    if (!number.startsWith('+374')) return false;
    const code = number.slice(4, 7) === '0' ? number.slice(4, 7) : number.slice(4, 6);
    const remaining = number.slice(4 + code.length);
    return allowedCodes.includes(code) && remaining.length >= 6 && /^[0-9]+$/.test(remaining);
  };



  function SubmitUserID(e) {
    e.preventDefault();
    if (userID) {
      axios({
        method: 'POST',
        url: baseURL + uidURL,
        data: { "uid": userID }
      }).then((res) => {
        setuserIdForm(false)
        setuserNumberForm(true)
        setProgress(50)
        setBack(true)
        seterrorCode('')
        setuserMobile(res.data.mobile)
      }
      ).catch((error) => {
        setuserIdForm(true)
        setuserNumberForm(false)
        setProgress(0)
        setBack(null)
        seterrorCode(error.response.data.error)
      });
    }
  };

  // useEffect(() => {
  //   if(){
  //     setMobButton(true)
  //   } else{
  //     setMobButton(false)
  //   }
  // }, [userNumber])

  function SubmitMobileNumber(e) {
    e.preventDefault()
    seterrorCode('')
    if (isValidArmenianNumber(userNumber)) {
      axios({
        method: 'POST',
        url: baseURL + signinURL,
        data: { "uid": userID, "mobile": userNumber }
      }).then((res) => {
        if (res.status === 200) {
          setuserCodeForm(true)
          setuserNumberForm(false)
          setProgress(100)
          seterrorCode('')
          setError_timer(60)
        }
      }).catch((error) => {
        if (error.response) {
          // console.log(error.response.data.error_message);
setCallError(error.response.data.error_message)
          setuserNumberForm(true)
          setProgress(50)
        }
      });
    };
  };

  useEffect(() => {
    if (userConfiramtion.length == 4) {
      SubmitCode()
    }
  }, [userConfiramtion])


  function SubmitCode(e) {
    seterrorCode('')
    if (isValidArmenianNumber(userNumber)) {
      axios({
        method: 'POST',
        url: baseURL + signinConfirmURL,
        data: { "uid": userID, "mobile": userNumber, "code": userConfiramtion }
      }).then(res => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token)
          window.location.replace('/user_account/dashboard')
          seterrorCode('')
        }
      }).catch((error) => {
        seterrorCode(error.response.data.error_code)
      });
    };
  };

  const [error_timer, setError_timer] = useState(0)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setError_timer((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [error_timer]);

  useEffect(() => {
    if (error_timer <= 0) {
      clearInterval(intervalRef.current);
    }
  }, [error_timer]);




  console.log(userNumber);
  return (
    <>
      <MetaTags title={'Անձնական էջ'} />

      <div className="col-sm-12 col-xs-12 p-0">
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col-md-7 col-sm-11 mx-auto pt-5 pb-2">
              <div className="cw-100 card b-radius b-shadow border-0 p-5">
                <div className="col-xl-10 col-lg-11 col-11 mx-auto">

                  <div className="text-center my-4">
                    <img style={{ width: '100px' }} src={small_logo} alt={alt_main} />
                    <div className="line-inside"><span>{t('nav_userlink')}</span></div>
                  </div>

                  <div className="col-xl-10 col-lg-10 col-11 mx-auto p-0 my-5 position-relative">
                    <ul className="d-flex justify-content-between align-items-center">
                      <span className={progress >= 0 ? "form-icon active" : "form-icon"}>
                        <FiUser></FiUser>
                      </span>
                      <span className={progress >= 50 ? "form-icon active" : "form-icon"}>
                        <FiPhone></FiPhone>
                      </span>
                      <span className={progress >= 100 ? "form-icon active" : "form-icon"}>
                        <FiUserCheck></FiUserCheck>
                      </span>
                    </ul>
                    <div className="progress">
                      <div className="progress-bar bg-success" role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progress + "%" }}
                      >
                      </div>
                    </div>
                  </div>


                  {userIdForm && <>
                    <form onSubmit={e => SubmitUserID(e)}>

                      <div className="text-center my-4">{t('_user_page_login_help_text_1')}
                        {errorCode ? <div className="text-danger w-100">{t('_user_page_login_help_text_error_1')}</div> : null}
                      </div>

                      <div className="form-floating my-5">
                        <input type="tel" className="form-control b-radius b-shadow mt-2 text-muted" id="userID" placeholder={t('_user_page_login_user_id')} autoFocus
                          value={userID}
                          onChange={e => { setuserID(e.target.value.replace(/\D/, '')); seterrorCode('') }}
                        />
                        <label htmlFor="userID">{t('_user_page_login_user_id')}</label>
                      </div>

                      <div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
                        <button type="button"
                          onClick={e =>
                            SubmitUserID(e)}
                          disabled={userID ? null : true}
                          className="net-btn green-bg green-shadow py-3 px-4 m-2">{t('_user_page_login_enter')} <FiArrowRight className="ms-2" />
                        </button>
                      </div>

                    </form>
                  </>}

                  {userNumberForm && <>
                    <form onSubmit={e => SubmitMobileNumber(e)}>
                      <div className="text-center my-4">{t('_user_page_login_help_text_2')} <span className="text-italic text-success"> {userMobile} </span>, {t('_user_page_login_help_text_2_1')}
                        {errorCode ? <><br /><span className="text-danger w-100">{t('_user_page_login_help_text_error_2')}</span></> : null}
                      </div>
                      <div className="form-floating my-5">
                        <PhoneInput
                          required
                          autoFocus
                          international
                          displayInitialValueAsLocalNumber
                          placeholder={userMobile}
                          className="form-control b-radius pl-4"
                          ref={inputRef}
                          value={userNumber}
                          onChange={setuserNumber}
                        />
                        <label htmlFor="phone">{t('_user_page_login_phone')}</label>
                      </div>
<span className="text-danger text-center col-12">{callError}</span>
                      <div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
  <button
    type="button"
    onClick={(e) => {
      setuserIdForm(true);
      setuserNumberForm(false);
      setuserID('');
      setProgress(0);
      seterrorCode('');
      setuserNumber('');
      setCallError(null)
    }}
    className="net-btn orange-bg orange-shadow py-3 px-4 m-1"
  >
    <FiArrowLeft className="me-2" /> {t('_user_page_login_go_back')}
  </button>
  <button
    type="submit"
    disabled={!userNumber || !isValidArmenianNumber(userNumber)}
    className="net-btn green-bg green-shadow py-3 px-4 m-2"
  >
    {t('_user_page_login_enter')} <FiArrowRight className="ms-2" />
  </button>
</div>

                    </form>
                  </>}

                  {userCodeForm && <>
                    <form onSubmit={e => { e.preventDefault(); SubmitCode(e) }}>

                      <div className="text-center mb-4">{t('_request_text_line_1')} 060XX<span className="text-danger">XXXX</span> {t('_request_text_line_2')}
                        <div className="small mt-2 col-12">
                          {error_timer > 0 ? <>{t('_request_error_text')} {error_timer} {t('_request_error_seconds')}' </> :
                            <button onClick={e => { SubmitMobileNumber(e); }}
                              className="btn green-text mt-2 p-0 m-0"><FiPhoneIncoming className="me-2" /> {t('_request_repeat')} </button>
                          }
                        </div>
                        {errorCode ? <><br /><span className="text-danger">{t('_request_error')}</span></> : null}
                      </div>
                      <div className="form-floating mb-5 mt-0">
                        <InputMask type='tel' required autoFocus className='form-control text-center b-radius b-shadow mt-2 text-muted fw-bold ls-30 mb-3'
                          mask="9999"
                          placeholder="_ _ _ _"
                          value={userConfiramtion}
                          onChange={e => setuserConfiramtion(e.target.value.replace(/[`~!@#$%^&*()_|\-=?;:'' '",.<>\\/s]/gi, ''))}
                        />
                      </div>

                      <div className="col-sm-12 mx-auto mt-4 mb-2 text-center">

                        <button type="button"
                          onClick={e => {
                            setuserNumberForm(true);
                            setuserCodeForm(false);
                            setProgress(50)
                            seterrorCode('')
                            setuserNumber('')
                            setuserConfiramtion('')
                          }} className="net-btn orange-bg orange-shadow py-3 px-4 m-2"><FiArrowLeft className="me-2" /> {t('_user_page_login_go_back')} </button>

                        <button type="submit"
                          onSubmit={e => SubmitCode(e) && userConfiramtion ? SubmitCode(e) : null}
                          disabled={userConfiramtion.length > 3 ? false : true} className="net-btn green-bg green-shadow py-3 px-4 m-2">{t('_user_page_login_enter')} <FiArrowRight className="ms-2" /></button>
                      </div>
                    </form>
                  </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}