import { Link } from 'react-router-dom';
import { FiMapPin, FiChevronRight, FiFacebook, FiUser, FiAtSign, FiPhoneCall, FiMap, FiInstagram } from 'react-icons/fi';
import IsoSertificate from '../../assets/docs/ISMS_ACCREDITED_Certificate+APPENDICES_ARPINET_EN_P-1.pdf';
import useFetch from '../../hooks/useFetch'
import logo from '../../assets/images/logo.png'
import face from '../../assets/images/icons/facebook.svg'
import insta from '../../assets/images/icons/insta.svg'
import linked from '../../assets/images/icons/linked.svg'



export default function Footer(props) {
    const { data } = useFetch(props.url)

    return (
        <footer className="col-sm-12 p-0 footer">
            <div className="sep-line"></div>
            <div className="container pt-5 pb-0 p-3 text-sm-center text-md-start">
                <div className="row mb-5">
                <div class="col-xl-4 col-12 pl-lg-0 py-3 mb-xl-0 mb-5 text-xl-start text-center">

                    <img class="text-center" src={logo} style={{width:" 300px"}}/>
                    <div class="row">
                        <div class="col-12 text-xl-start text-center mb-lg-0 mb-3">
                            <div class="m-0 fw-bold mt-3">{props.t('_footer_addr_official_address')}<br/>
                            {props.t('_footer_addr_official_address_line')}</div>
                            <div class="d-md-flex d-block justify-content-xl-start justify-content-center text-muted my-2">
                                <div class="fw-bold my-md-0 my-3">
                                    <a class="text-muted d-inline-flex" href="tel:+37460888888">+37460888888</a>
                                </div>
                                <div class="mx-3 d-none d-md-block">|</div>
                                <div class="fw-bold my-md-0 my-3">
                                    <a class="text-muted" href="mailto:info@arpinet.am">info@arpinet.am</a>
                                </div>
                                <div class="mx-3 d-none d-md-block">|</div><div class="fw-bold my-md-0 my-3">
                                    <a class="text-muted" href="mailto:hr@arpinet.am">hr@arpinet.am</a>
                                </div>
                            </div>
                            <div class="row mt-4 d-flex justify-content-xl-start justify-content-center text-xl-start text-center">
                                <a target="blank" href="https://www.facebook.com/@arpinet.am/" class="social-links mx-1">
                                    <img src={face} class="w-100"/>
                                </a>
                                <a target="blank" href="https://www.instagram.com/arpinet.llc/" class="social-links mx-1">
                                    <img src={insta} class="w-100"/>
                                </a>
                                <a target="blank" href="https://am.linkedin.com/company/arpinet" class="social-links mx-1">
                                    <img src={linked} class="w-100"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>



                    <div className="col-xl-4 col-md-6 col-10 mx-auto my-3 my-md-0 pt-3 p-2 pr-4 links text-uppercase">
                        <h4 className="mb-4">{props.t("_footer_links")}</h4>
                        <ul className="col-lg-10 col-sm-11">
                            <li className="d-block">
                                <Link className="pt-3 pb-2 col-12" to="/news">
                                <FiChevronRight className="me-2" />{props.t('_news')}</Link>
                                </li>
                            <li className="d-block">
                                <Link className="pt-3 pb-2 col-12" to="/notifications">
                                <FiChevronRight className="me-2" />{props.t('_footer_links_notify')}</Link>
                                </li>
                            <li className="d-block">
                                <Link className="pt-3 pb-2 col-12" to="/career">
                                <FiChevronRight className="me-2" />{props.t('_footer_links_job')}</Link>
                                </li>
                            <li className="d-block">
                                <Link className="pt-3 pb-2 col-12" to="/online-cv">
                                <FiChevronRight className="me-2" />{props.t("_footer_links_online_cv")}</Link>
                                </li>

                            <li className="d-block">
                                <Link className="pt-3 pb-2 col-12" to="/contacts">
                                <FiChevronRight className="me-2" />{props.t("_footer_links_contacts")}</Link>
                                </li>

                        </ul>
                    </div>

                    <div className="col-xl-4 col-md-6 col-10 mx-auto my-3 my-md-0 locations text-uppercase text-md-start text-center">
                        <h4 className="mb-4">{props.t('_service_centers')}</h4>
                        <div className="row d-flex align-items-center">
                            {
                                data?.map((addresses) => (
                                    <p key={addresses.id} className="my-1 pb-1"><FiMapPin className="me-2 green-text" />{addresses.city}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div class="container py-3">
            <hr className="my-3 p-0 "/>
                <div class="col-12 text-center text-uppercase py-3">
                    <div class="d-lg-flex d-grid justify-content-lg-between ">
                    <Link className="me-3 text-muted my-2" to="/about_us">{props.t("_footer_links_about")}</Link>
                    <Link className="me-3 text-muted my-2" to="/terms">{props.t("_footer_links_terms")}</Link>
                        <a class="me-3 text-muted my-2" href="/privacy_and_policy">{props.t('_footer_privacy_and_policy')}</a>
                        <Link className="me-3 text-muted my-2" to="/report">{props.t("_footer_links_reports")}</Link>
                        <a class="me-3 text-muted my-2" href="/static/media/ISMS_ACCREDITED_Certificate+APPENDICES_ARPINET_EN_P-1.75ae42477d2baad7a9b0.pdf" target="blank">ISO հավաստագիր</a>
                    </div>
                </div>
            </div>

            <div className="footer-bg pt-3">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 p-0 text-lg-start text-md-center text-sm-center mb-3">
                            <p className="mb-1">© {props.t("_footer_copyright_text_1")} - {new Date().getFullYear()} </p>
                            <p className="mb-0">{props.t("_footer_copyright_text_2")}</p>
                        </div>
                        <div className="col-lg-6 col-sm-12 p-0 text-lg-end text-md-center text-sm-center mb-3">
                            <p className="mb-1">{props.t('_footer_copyright_text_3')}</p>
                            <p className="mb-0">{props.t('_footer_copyright_text_4')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
