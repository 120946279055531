import React, { useState, useEffect } from 'react'
import { baseURL, careerURL, adressessURL } from '../static/api'
import axios from 'axios'
import MetaTags from './ui-elements/MetaTags'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { FiMapPin } from 'react-icons/fi'
import useFetch from '../hooks/useFetch'


export default function CareerPage() {
    const { t } = useTranslation();
    const lng = localStorage.getItem('i18nextLng')

    const [state, setState] = useState([])
    const [filter, setFilter] = useState("all");
    const [active, setActive] = useState(false)
    const [addresses, setAddreses] = useState()

    useEffect(() => {
        axios.get(baseURL + careerURL, { headers: { "Accept-Language": lng.substring(0, 2) } }).then((res => {
            const add = res?.data.map(obj => ({
                ...obj,
                tag: ["all", obj.location],
                filtered: true,
                error_headline: 'Այս պահին տվյալ մասնաճյուղում թափուր աշխատատեղ չկա:',
                error_text: 'Հնարավոր է շուտով նոր հայտարարություններ լինեն։'
            }))
            setState(add)
        }))
        axios.get(baseURL + adressessURL, { headers: { "Accept-Language": lng.substring(0, 2) } }).then((res => { setAddreses(res.data) }))
    }, [setFilter, lng])




    useEffect(() => {
        setState([]);
        const filtered = state.map(state => ({
            ...state,
            filtered: state.tag.includes(filter),

        }));
        setState(filtered);
    }, [filter]);




    return (
        <div className='col-12 float-left p-0 py-5'>
            <MetaTags title={'Թափուր աշխատատեղեր'} />
            <div className="container pb-5">
                <div className="card b-radius b-shadow border-0 mb-5 p-5 text">
                    <div className="row">
                        <div className="col-12 p-0 mb-5">
                            <div className="row m-0 p-0">
                                <button className="w-auto b-radius b-shadow border-0 small py-1 px-3 m-1 text-muted bg-white d-flex align-items-center" onClick={() => setFilter("all")}> {t('_career_page_all_')} </button>
                                {addresses?.map((a, i) =>
                                    <button
                                        key={i}
                                        className="w-auto b-radius b-shadow border-0 small py-1 px-3 m-1 text-muted bg-white d-flex align-items-center"
                                        onClick={() => { setFilter(a.city); setActive(true) }}
                                    >
                                        {t(a.city)}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {state.length > 0 ?
                            state.map(c => c.filtered === true ?
                                <div key={c.id + c.end} className="col-lg-4 col-md-6 col-sm-6 col-12 card-icons stretch-card">
                                    <Link to={`/career/${c.id}`} className='card w-100 b-radius b-shadow border-0 p-3 my-2 stretch-card'>
                                        <div className="row">
                                            <img src={c.image} />
                                            <div className="col-12 pt-3">
                                                <div className='h6 fw-bold text-uppercase gradient-green-bg gradient-text m-0'>{c.position}</div>
                                                <div className='small text-muted text-uppercase d-flex align-items-center'>
                                                    <FiMapPin className='small me-2' />{c.location}
                                                </div>
                                                <div className='small text-muted text-uppercase d-flex align-items-center'>
                                                    {t('_career_page_deadline_')} {c.end}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                : null)
                            :
                            <div className='col-12 text-center text py-5 my-5'>
                                <div className='h5'>{t('_career_page_not_avaible_1')}</div>
                                <div className=''>{t('_career_page_not_avaible_description_')}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
