import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL, payments } from '../../static/api';
import { useLocation } from 'react-router-dom';
import { FiActivity, FiChevronsRight, FiChevronsLeft } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

export default function PaymentHistory() {
    const location = useLocation();
    const agrm_id = location.state?.agrm_id;
  const { t } = useTranslation();

    const [state, setState] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
         axios.get(`${baseURL}${payments}?agrm_id=${agrm_id}&pg_size=10&pg_num=${page}`)
            .then(res => {

                setState(res.data.payments);
                setTotalPages(res.data.total_pages); // Assuming the API provides total pages
            });
    }, [agrm_id]);

    const handleNext = () => {
        if (page < totalPages) {
            setPage(prev => prev + 1);
        }
    };

    const handlePrev = () => {
        if (page > 1) {
            setPage(prev => prev - 1);
        }
    };

    const renderPagination = () => {
        const maxPagesToShow = 5; // Max number of page buttons to show
        const pages = [];
        const startPage = Math.max(1, page - 2);
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => setPage(i)}
                    className={`  ${i === page ? 'mx-2 px-3 py-2 b-radius b-shadow bg-light border active green-bg green-shadow text-white' : 'mx-2 px-3 py-2 b-radius b-shadow orange-text'} mx-1`}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination justify-content-center">
                <button
                    className="mx-1"
                    onClick={handlePrev}
                    disabled={page === 1}
                >
                    <FiChevronsLeft/>
                </button>
                {pages}
                {endPage < totalPages && <span className="mx-2">...</span>}
                <button
                    className="mx-1"
                    onClick={handleNext}
                    disabled={page === totalPages}
                >
                    <FiChevronsRight/>
                </button>
            </div>
        );
    };

    return (
        <div className="card w-100 b-radius b-shadow p-4 border-0">
            <h2 className="text-uppercase mb-4">{t('_user_page_online_payment_history_title')}</h2>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">{t('_user_page_online_payment_history_type')}</th>
                            <th scope="col">{t('_user_page_online_payment_history_ammount')}</th>
                            <th scope="col">{t('_user_page_online_payment_history_date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state?.map(item => (
                            <tr key={item.id || item.class_name}>
                                <td>
                                    <div className="d-flex align-items-center my-2">
                                        <FiActivity className="me-3" /> {item.class_name}
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center my-2">
                                        <span className="lh-0">
                                            {item.amount}
                                            <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="lh-0 ms-1 small"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M4 10a6 6 0 1 1 12 0v10"></path>
                                                <path d="M12 16h8"></path>
                                                <path d="M12 12h8"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <span className="lh-0">{item.local_date}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {renderPagination()}
        </div>
    );
}
