import { FiAlertCircle } from "react-icons/fi";

export default function ErrorPage(props) {
    return (
        <div className="col-12 pt-5 pb-5 p-0">
            <div className="container">
                <div className="d-flex flex-column align-items-center">
                    <FiAlertCircle className='text-danger fw-bold mb-5' style={{ fontSize: '17rem' }} />
                    <div className='fs-3 fw-bold text-uppercase'>{props?.t('_error_page_headline')}</div>
                    <a className='green-text fs-5 fw-bold' href="/">{props?.t('_error_page_link')}</a>
                </div>
            </div>
        </div>
    )
}
