import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import NoAvatar from '../assets/images/icons/no-avatar.png'
import axios from 'axios'
import {baseURL, cvURL, identify, identify_confirm} from '../static/api'
import {isValidPhoneNumber} from 'react-phone-number-input/mobile'
import InputMask from "react-input-mask";
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import MetaTags from './ui-elements/MetaTags'

export default function OnlineCV() {
    const { t } = useTranslation();
    const [avatar, setAvatar] = useState(null);
    const [imagePreviewUrl, setImagePreviewURL] = useState('');
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [birth_date, setBirthDate] = useState('')
    const [address, setAddress] = useState('')
    const [study, setStudy] = useState('')
    const [lang_armenian, setArmenian] = useState('')
    const [lang_russian, setRussian] = useState('')
    const [lang_english, setEnglish] = useState('')
    const [other_languages, setOtherLNG] = useState('')
    const [skills, setSkills] = useState('')
    const [work_experience, setWork] = useState('')
    const [did_u_ask, setDIA] = useState('')
    const [other_information, setOtherINFO] = useState('')
    const [error, setError] = useState('')
    const [formAccess, setFormAccess] = useState(true)
    const [userConfiramtion, setUserConfirmation] = useState('')
    const [formConfirm_identification, setFormConfirm_identification] = useState(false)
    const [formMessage, setFormMassage] = useState(false)
    // console.log(lang_armenian, lang_russian, lang_english);

    function handleImageChange(e){
        setAvatar(e.target.files[0])
        setImagePreviewURL(URL.createObjectURL(e.target.files[0]))
    };

    function removeImage() {
        setAvatar(null);
        setImagePreviewURL(null)
    }

    function identifyRequest(e) {
    e.preventDefault();
    if(isValidPhoneNumber(mobile)){
      axios({
          method: 'POST',
          url: baseURL+identify,
          data: {"mobile": '+'+mobile.replace(/[`~!@#$%^&*()_|+\-=?;:'' '",.<>s]/gi, '')}
      }).then((res)=>{
          if(res.status === 200) {
              setFormAccess(false);
              setFormConfirm_identification(true)
          }
        }
        ).catch((error) => {
            setFormAccess(true)
            setFormConfirm_identification(false)
        });
      };
    };

    function SubmitCode(e){
      e.preventDefault();
      axios({
        method: 'POST',
        url: baseURL+identify_confirm,
        data: {"mobile": mobile, "code": userConfiramtion}
      }).then(res => {
          if(res.status===200){
              onSubmit();
              setFormMassage(true);
              setFormConfirm_identification(false)
        }
      }).catch((error) => {
        //   console.log(error.response);
    });
  };

    function onSubmit(){
    let form_data = new FormData();
        form_data.append('avatar', avatar, avatar?.name);
        form_data.append('first_name', first_name);
        form_data.append('last_name', last_name);
        form_data.append('email', email);
        form_data.append('mobile', '+'+mobile.replace(/[`~!@#$%^&*()_|+\-=?;:'' '",.<>s]/gi, ''));
        form_data.append('birth_date', birth_date);
        form_data.append('address', address);
        form_data.append('study', study);
        form_data.append('lang_armenian', lang_armenian);
        form_data.append('lang_russian', lang_russian);
        form_data.append('lang_english', lang_english);
        form_data.append('other_languages', other_languages);
        form_data.append('skills', skills);
        form_data.append('work_experience', work_experience);
        form_data.append('did_u_ask', did_u_ask);
        form_data.append('other_information', other_information);
    axios.post(baseURL+cvURL, form_data, {
        headers: {'content-type': 'multipart/form-data'}
    }).then( res => {
      
    }).catch((error) => {
        if (error.response) {
            setError(error.response.data)
        }
    })
};


return (
  <div className="col-xs-12 p-0 pt-5">
    <MetaTags title={'Առցանց ինքնակենսագրական'}/>
        <div className="container">
            <div className="feature-box article mb-5 p-5">
            {formAccess ? 
            <>
            <p className="text-center pb-2 col-10 mx-auto">{t('_online_cv_main_text_1')} <a href="mailto:hr@arpinet.am" className="green-text">hr@arpinet.am</a> {t('_online_cv_main_text_2')}</p>
            <form onSubmit={address && birth_date && did_u_ask && email && first_name && lang_armenian && lang_english && lang_russian && last_name && skills && study && work_experience && isValidPhoneNumber(mobile) ? (e) => identifyRequest(e) : false} noValidate className='application needs-validation'>
                <div className="row">

                    <div className='card border-0 b-radius b-shadow p-4'>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 mt-3 text-center">
                            <div className="fileUpload upload-btn btn text-center">
                                <input type="file" className="uploadlogo" accept="image/x-png,image/gif,image/jpeg" max-size="2097152"  
                                    onChange={handleImageChange} 
                                />
                                {imagePreviewUrl &&  <div className="ft-x removeimage" onClick={removeImage}></div>}
                                <img id="img" className="imagepreview"  src={imagePreviewUrl ? imagePreviewUrl : NoAvatar} alt="Կցել լուսանկար" />  
                            </div>
                        </div>

                        <div className="col-md-9 col-sm-12">
                            <div className="row">
                                <div className="form-floating mb-3 mt-3 col-md-6 col-sm-12 stretch-card">
                                    <input type="text" required className={error?.first_name ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="first_name" placeholder={t('_online_cv_name')}
                                        value={first_name}
                                        onChange={e => setFirstName(e.target.value)}
                                    />
                                    <label htmlFor="first_name">{t('_online_cv_name')}</label>
                                </div>

                                <div className="form-floating mb-3 mt-3 col-md-6 col-sm-12 stretch-card">
                                    <input type="text" required className={error?.last_name ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="last_name" placeholder={t('_online_cv_surname')}
                                        value={last_name}
                                        onChange={e => setLastName(e.target.value)}
                                    />
                                    <label htmlFor="last_name">{t('_online_cv_surname')}</label>
                                </div>
                                
                                <div className="form-floating mb-3 mt-3 col-md-6 col-sm-12 stretch-card">
                                    <MaskedInput type="text" className={error?.email ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="email" placeholder={t('_online_cv_e_mail')}
                                        value={email}
                                        mask={emailMask}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    <label htmlFor="email">{t('_online_cv_e_mail')}</label>
                                </div>

                                <div className="form-floating mb-3 mt-3 col-md-6 col-sm-12 stretch-card">
                                    <InputMask type="tel" className={error?.mobile ? "form-control b-radius mt-2 is-invalid" : "form-control b-radius mt-2"} id="mobile"
                                        value={mobile} 
                                        onChange={e => setMobile(e.target.value)}
                                        mask="+374 (99) 99-99-99"
                                        placeholder="+374 (99) 99-99-99"
                                    />
                                    <label htmlFor="mobile">{t('_mobile')}</label>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group mb-3 mt-3 col-lg-3 col-md-4 col-sm-12 stretch-card">
                            <input type="date" className={error?.birth_date ? 'form-control b-radius mt-2 is-invalid pt-2 pb-2 p-2' : 'form-control b-radius mt-2 pt-2 pb-2 p-2'} 
                                value={birth_date} 
                                onChange={e => setBirthDate(e.target.value)}
                                data-date=""
                                data-date-format="08-19-2020" 
                                />
                            <datalist id="dateList"></datalist>
                        </div>
                                
                        <div className="form-floating mb-3 mt-3 col-lg-9 col-md-8 col-sm-12 stretch-card">
                            <input type="text" className={error?.address ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="address" placeholder={t('_online_cv_address')}
                                value={address}
                                onChange={e => setAddress(e.target.value)}
                            />
                            <label htmlFor="address">{t('_online_cv_address')}</label>
                        </div>
                    </div>
                </div>
                        
                <div className='card border-0 b-radius b-shadow p-4 mt-5'>
                    <div className="row">
                        <p className='align-content-center'><i className='ft-info mr-2 text-info'></i>{t('_online_cv_learning_text_1')}</p>
                        <div className='col-md-7 col-sm-12'>
                            <div className="col-md-12">
                                <label htmlFor="university" className="small text-muted text-uppercase">{t('_online_cv_learning_text_2')}</label>
                                <textarea type='text' rows="4" style={{resize:'none'}} 
                                value={study} 
                                onChange={e => setStudy(e.target.value)}
                                className={error?.address ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'}></textarea>
                            </div>

                            <div className="col-md-12 pt-2 mb-1">
                                <label htmlFor="skills" className="small text-muted">{t('_online_cv_technical_')}</label>
                                <textarea type='text' rows="4" style={{resize:'none'}} 
                                value={skills} 
                                onChange={e => setSkills(e.target.value)}
                                className={error?.skills ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="skills"></textarea>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <label htmlFor="university" className="small text-muted">{t('_online_cv_lng_')}</label>
                            <table className="table table-borderless table-hover col-10">
                                <thead>
                                    <tr>
                                        <th className='col-sm-3'></th>
                                        <th className="small text-center col-sm-3">{t('_online_cv_lng_excelent')}</th>
                                        <th className="small text-center col-sm-3">{t('_online_cv_lng_fine')}</th>
                                        <th className="small text-center col-sm-3">{t('_online_cv_lng_good')}</th>
                                    </tr>
                                </thead>
                                <tbody className="mb-2 text-center justify-content-center">
                                    <tr className={error?.lang_armenian ? 'table-danger' : ''} onChange={e => setArmenian(e.target.value)}>
                                        <td className="small">{t('_online_cv_lng_armenian')}</td>
                                        <td>  
                                            <div className="form-check d-flex justify-content-center" >
                                                <input type="radio" id="armenian_1"  value="0" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="armenian_1"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="armenian_2" value="1" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="armenian_2"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="armenian_3" value="2" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="armenian_3"></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={error?.lang_russian ? 'table-danger' : ''} onChange={e => setRussian(e.target.value)}>
                                        <td className="small">{t('_online_cv_lng_russian')}</td>
                                        <td>  
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="russian_1" value='0' className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="russian_1"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="russian_2" value='1' className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="russian_2"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="russian_3" value='2' className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="russian_3"></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={error?.lang_english ? 'table-danger' : ''} onChange={e => setEnglish(e.target.value)}>
                                        <td className="small">{t('_online_cv_lng_english')}</td>
                                        <td>  
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="english_1" value='0' className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="english_1"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="english_2" value='1' className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="english_2"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check d-flex justify-content-center">
                                                <input type="radio" id="english_3" value='2' className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="english_3"></label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="form-floating mb-3 mt-4 col-sm-12 stretch-card">
                                <input type="text" className={error?.email ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="other_languages" placeholder={t('_online_cv_lng_experiance_other')}
                                    value={other_languages}
                                    onChange={e => setOtherLNG(e.target.value)}
                                />
                                <label htmlFor="other_languages">{t('_online_cv_lng_experiance_other')}</label>
                            </div>
                        </div>     
                    </div>
                </div>
                                
                <div className='card border-0 b-radius b-shadow p-4 mt-5'>
                    <div className="row">
                        <p className='align-content-center'><i className='ft-info mr-2 text-info'></i>{t('_online_cv_work_experiance')}</p>

                        <div className="col-md-7 pt-2 mb-1">
                            <label htmlFor="work_experience" className="small text-muted">{t('_online_cv_work_experiance')}</label>
                            <textarea type='text' rows="4" style={{resize:'none'}} value={work_experience} onChange={e => setWork(e.target.value)} className={error?.work_experience ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="work_experience"></textarea>
                        </div>

                                
                        <div className="col-md-5 pt-2 mb-1" onChange={e => setDIA(e.target.value)}>
                            <label htmlFor="did_u_ask" className="small text-muted">{t('_online_cv_did_you_ask_text')}</label>
                            <div className="form-check d-flex align-items-center">
                                <input type="radio" id="did_u_ask-1" defaultValue='0' className="form-check-input"/>
                                <label className="ml-3 form-check-label" htmlFor="did_u_ask-1">{t('_online_cv_did_you_ask_yes')}</label>
                            </div>
                            <div className="form-check d-flex align-items-center">
                                <input type="radio" id="did_u_ask-2" defaultValue='1' className="form-check-input"/>
                                <label className="ml-3 form-check-label" htmlFor="did_u_ask-2">{t('_online_cv_did_you_ask_no')}</label>
                            </div>
                            <div className="form-check d-flex align-items-center">
                                <input type="radio" id="did_u_ask-3" defaultValue='2' className="form-check-input"/>
                                <label className="ml-3 form-check-label" htmlFor="did_u_ask-3">{t('_online_cv_did_you_ask_dont_remember')}</label>
                            </div>
                            <p className="small text-danger">{error?.did_u_ask ? <>{t('_online_cv_error_text')}</> : null}</p>
                        </div>

                        <div className="col-md-12 pt-2 mb-1">
                            <label htmlFor="other_information" className="small text-muted">{t('_online_cv_other_information')}</label>
                            <textarea type='text' rows="2" style={{resize:'none'}} value={other_information} onChange={e => setOtherINFO(e.target.value)} className={error?.other_information ? 'form-control b-radius mt-2 is-invalid' : 'form-control b-radius mt-2'} id="other_information"></textarea>
                        </div>
                    </div>
                </div>


                <div className="form-group col-12 text-center  mt-4">
                    <button type="submit" className="net-btn green-bg green-shadow pr-3 pl-3 pt-2 pb-2 mb-4 mr-3"
                    onSubmit={address && birth_date && did_u_ask && email && first_name && lang_armenian && lang_english && lang_russian && last_name && skills && study && work_experience && isValidPhoneNumber(mobile) ? (e) => identifyRequest(e) : false}
                    disabled={address && birth_date && did_u_ask && email && first_name && lang_armenian && lang_english && lang_russian && last_name && skills && study && work_experience && isValidPhoneNumber(mobile) ? false : true}
                    >{t('_confirm')}</button>
                </div>

            </div>
        </form>
        </>
        : null}	
        
        {formConfirm_identification ? (
        <div className="col-lg-6 col-md-7 col-sm-10 col-xs-10 mx-auto p-2">
            <form onSubmit={e => SubmitCode(e)}>
                <div className="text-center mb-5">{t('_request_text_line_1')} 060XX<span className="text-danger">XXXX</span> {t('_request_text_line_2')}
                {error ? <><br/><span className="text-danger">{t('_request_error')}</span></> : null}
                </div>
                    <div className="form-floating mb-5 mt-3">
                    <InputMask  autoFocus className='form-control b-radius p-2 pl-4 pr-4 text-center fw-bold ls-30 mb-3' id="userConfiramtion"
                        mask="9999"
                        placeholder="_ _ _ _"
                        value={userConfiramtion} 
                        onChange={e => setUserConfirmation(e.target.value)}
                    />
                </div>

                <div className="col-sm-12 mx-auto mt-4 mb-2 text-center">        
                    <button type='submit' 
                    onClick={userConfiramtion.length === 4 ? e => SubmitCode(e) : null} 
                    disabled={userConfiramtion.length === 4 ? false : true} 
                    className="net-btn green-bg green-shadow pr-3 pl-3 pt-2 pb-2 mb-4 mr-3">{t('_confirm')} <i className="ft-arrow-right ml-2"></i></button>       
                </div>
            </form> 
        </div>
        ) : null}

        {formMessage ? (
        <div className='col-lg-5 col-md-6 col-sm-10 col-xs-10 mx-auto'>
          <div className="text-center pt-5 pb-5 mt-3">
              <p className="green-text text-uppercase h4 pb-4">{t('_call_req_done_line_1')}</p>
              <p className="text-muted">{t('_call_req_done_line_2')}</p>
          </div>
        </div>
        ) : null}
                 
        </div>		
    </div>		
</div>)
}



