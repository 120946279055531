import { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { Link } from 'react-router-dom';
import MetaTags from '../ui-elements/MetaTags';
import axios  from 'axios';
import Loader from '../Loader';
import { baseURL, aboutURL } from '../../static/api';

export default function AboutCompany(props) {
    const lng = localStorage.getItem('i18nextLng')
    // const { data, loading } = useFetch(props.url)
    const [state, setState] = useState()


    useEffect(() => {
        axios.get(baseURL+aboutURL, { headers: { "Accept-Language": lng.substring(0, 2) } }).then((res) => {
             const data = res.data[0];
            setState({
                description: data.description,
                partners: data.partners,

            });
        });
    }, [lng]);

console.log(state);


    return (
        <div>
            <MetaTags title={'Մեր մասին'} />

            {!state ?
                <div className="col-12 float-left p-0 py-5">
                    <div className="container pb-5">
                        <div className="feature-box article mb-5 p-5">
                            <div className="p-5">
                                <Loader/>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='row'>
                    <div className="col-12 p-0">
                        <div className="container p-0">
                            <div className="card b-radius b-shadow border-0 p-5 my-5">
                                {state.description}
                            </div>
                        </div>
                        <div className="sep-line"></div>
                    </div>

                    <div className="col-12 p-0">
                        <div className="container p-0">
                            <div className="card b-radius b-shadow border-0 px-5 py-3 my-5">
                                <div className="gradient-orange-bg gradient-text text-uppercase fs-2 fw-bold text-center py-4">{props?.t('_about_main_partners')}</div>
                                <div className="row pb-5">
                                    {state ? state?.partners?.map((item, i) =>
                                        item.category === '0' &&
                                        <div key={i} className="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
                                            <Link to={''}>
                                                <img className='w-75 b-shadow b-radius p-0 m-3' src={item.image} alt="Arpinet LLC" />
                                            </Link>
                                        </div>
                                    ) :  <Loader/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="sep-line"></div>
                    </div>
                    <div className="col-12">
                        <div className="container p-0">
                            <div className="card b-radius b-shadow border-0 px-5 py-3 my-5">
                                <div className="gradient-orange-bg gradient-text text-uppercase fs-2 fw-bold text-center py-4">{props?.t('_about_cultural_partners')}</div>
                                <div className="row pb-5">
                                    {state ? state?.partners?.map((item, i) =>
                                        item.category === '1' &&
                                        <div key={i} className="col-lg-2 col-md-3 col-sm-4 col-6 p-0">
                                            <Link to={''}>
                                                <img className='w-75 b-shadow b-radius p-0 m-3' src={item.image} alt="Arpinet LLC" />
                                            </Link>
                                        </div>
                                    ) : <Loader/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
