import React, { useEffect, useState, lazy } from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { baseURL, tariffsURL, locationsURL } from '../static/api'
import TVpageSlider from './components/TVpageSlider'
import TariffTVComponent from './components/TariffTVComponent'
import { FaExclamationTriangle, FaExclamationCircle, FaMapMarker, FaDesktop } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';
import en_lg_internet_tv from '../assets/images/slider/en_lg_internet_tv.jpg'
import hy_lg_internet_tv from '../assets/images/slider/hy_lg_internet_tv.jpg'
import ru_lg_internet_tv from '../assets/images/slider/ru_lg_internet_tv.jpg'
import en_sm_internet_tv from '../assets/images/slider/en_sm_internet_tv.jpg'
import hy_sm_internet_tv from '../assets/images/slider/hy_sm_internet_tv.jpg'
import ru_sm_internet_tv from '../assets/images/slider/ru_sm_internet_tv.jpg'
import MetaTags from './ui-elements/MetaTags'

export default function TVpage() {
    const lng = localStorage.getItem('i18nextLng')
    const { t } = useTranslation();
    const [state, setState] = useState({
        load: null,
        iptv: [],
        catv: [],
        IPTVpoints: [],
        CATVpoints: []
    })

    function FatchData() {
        axios.all([
            axios.get(baseURL + tariffsURL + `?iptv=true`),
            axios.get(baseURL + tariffsURL + `?catv=true`),
            axios.get(baseURL + locationsURL),
            axios.get(baseURL + tariffsURL + `?catv=true&price=5500`)])
            .then(axios.spread((...responses) => {
                setState({
                    iptv: responses[0].data,
                    catv: responses[1].data,
                    IPTVpoints: responses[2].data,
                    CATVpoints: responses[3].data,
                    load: true
                });
            }))
    }

    // useEffect(() => {
    //     if(state.load == null){FatchData()}
    // }, [setState]);
    useEffect(() => {
        FatchData()
    }, []);

    const MapIPTV = lazy(() => {
        return new Promise(resolve => {
            setTimeout(() => resolve(import("./components/MapPoints.jsx")), 500);
        });
    });
    const MapCATV = lazy(() => {
        return new Promise(resolve => {
            setTimeout(() => resolve(import("./components/MapPointsCaTV.jsx")), 1000);
        });
    });

    return (
        <>
            <MetaTags
                title={'Թվային հեռուստաալիքներ'}
            />
            <div className="col-12 p-0">
                <div className="container pb-0">
                    <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_internet_tv : hy_lg_internet_tv) : en_lg_internet_tv
                        } />
                    <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_internet_tv : hy_sm_internet_tv) : en_sm_internet_tv
                        } />
                </div>
                <div className="sep-line mt-2"></div>
            </div>

            <div className="col-12 p-0 pt-5 ">
                <div className="container">
                    <div className="h3 text-center gradient-orange-bg gradient-text pb-2">{t('_iptv_main_headline')}</div>
                    <div className="row justify-content-center text-center my-5">
                        <TVpageSlider />
                    </div>
                </div>
                <div className="sep-line"></div>
            </div>

            <div className="col-12 pt-5 p-0 pb-5 pricing">
                <div className="container">
                    <div className="h3 text-center gradient-orange-bg gradient-text pb-2 pb-4 text-uppercase">{t('_iptv_main_tv_and_internet')}</div>
                    <div className="row justify-content-center text-center">
                        {state.load == true ? <TariffTVComponent data={state.iptv} className="justify-content-center" /> : null}
                    </div>
                    <div className="col-12 pt-5 pb-5">
                        <div className="row">
                            <div className="col-lg-6 col-lg-6 col-md-12 col-xs-12 text-muted small text-justify float-left">
                                <FaExclamationTriangle className="orange-text me-2" />{t('_iptv_main_attention')}</div>
                            <div className="col-lg-6 col-lg-6 col-md-12 col-xs-12 text-muted small text-justify float-left">
                                <FaExclamationCircle className="orange-text me-2" />{t('_iptv_main_change_plan')}</div>
                        </div>
                    </div>
                </div>
                <div className="sep-line"></div>
            </div>


            <div className="col-12 pt-5 p-0 pb-5 pricing">
                <div className="container pb-5">
                    <div className="h3 text-center gradient-orange-bg gradient-text pb-2 pb-4 text-uppercase">{t('_iptv_main_catv_headline')}</div>
                    <div className="row justify-content-center text-center">
                        {state.load == true ? <TariffTVComponent data={state.catv} /> : null}
                    </div>
                </div>
                <div className="sep-line"></div>
            </div>

            <div className="col-12 pt-5 p-0 pb-5">
                <div className="container">
                    <h3 className="gradient-orange-bg gradient-text pb-2 text-center text-uppercase">{t('_availability_map')}</h3>
                    <p className="text">{t('_availability_map_iptv_catv_description')}</p>
                    <div className="col-xs-12 p-0 pt-5 pb-5">
                        <div className="container">
                            <ul className="nav nav-tabs justify-content-center border-0 mb-5" id="maps" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="net-btn green-bg green-shadow text-uppercase py-3 px-4 m-2 active" id="iptv_map-tab" data-bs-toggle="tab" data-bs-target="#iptv_map" type="button" role="tab" aria-controls="iptv_map" aria-selected="true">
                                        <FiMonitor className="me-2" />{t('_availability_map_iptv')}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="net-btn green-bg green-shadow text-uppercase py-3 px-4 m-2" id="catv_map-tab" data-bs-toggle="tab" data-bs-target="#catv_map" type="button" role="tab" aria-controls="catv_map" aria-selected="false">
                                        <FaDesktop className="me-2" />{t('_availability_map_catv')}</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="mapsContent">
                                <div className="tab-pane show active" id="iptv_map" role="tabpanel" aria-labelledby="iptv_map-tab">
                                    {state.load == true ? <MapIPTV data={state.IPTVpoints} /> : null}
                                </div>
                                <div className="tab-pane" id="catv_map" role="tabpanel" aria-labelledby="catv_map-tab">
                                    {state.load == true ? <MapCATV data={state.CATVpoints} /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pl-0 pr-0 pb-5 ">
                        <div className="text text-justify"><FaMapMarker className="green-text me-2" />{t('_availability_green')}</div>
                        <div className="text text-justify"><span className='orange-text me-2'>*</span> {t('_availability_attantion')}</div>
                    </div>
                </div>
            </div>
        </>
    )
}


