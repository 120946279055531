import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png'
import { FiMenu, FiX } from 'react-icons/fi';
import { NAVIGATIONBARITEMS } from '../../static/StaticJson';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import lng_hy from '../../assets/images/icons/armenia.png'
import lng_en from '../../assets/images/icons/united-states.png'
import lng_ru from '../../assets/images/icons/russia.png'

export default function NavBar() {
    const { t } = useTranslation();
    const lng = localStorage.getItem('i18nextLng')
    const token = localStorage.getItem('token');

    function handleClick(lang) {
        i18next.changeLanguage(lang)
    }


    return (
        <>
            <nav className="navbar navbar-expand-lg position-fixed top-0 bg-blur b-shadow">
                <div className="container">
                    <Link to="/" className="logo text-center">
                        <img src={logo} alt="Arpinet LLC" />
                    </Link>
                    <Link to="/" className="navbar-toggler nav-link" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false">
                        <FiMenu />
                    </Link>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <Link to="/" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false">
                            <FiX />
                        </Link>
                        <ul className="navbar-nav text-uppercase ">
                            {NAVIGATIONBARITEMS.map((n, i) =>
                                <li key={i} className="nav-item">
                                    <NavLink className="nav-link text-muted" to={n.link}>{t(`${n.title}`)}</NavLink>
                                </li>
                            )}

                            <li className="nav-item">
                                <NavLink className="nav-link text-muted" to={token ? '/user_account/dashboard' : "/login"}>{t('nav_userlink')}</NavLink>
                            </li>

                            {token &&
                                <li className="nav-item">
                                    <NavLink className="nav-link text-muted" to="/logout">{t('nav_logout')}</NavLink>
                                </li>}
                            <li className="nav-item language-switcher">
                                <button onClick={() => handleClick('en-US')}><img src={lng_en} alt="Arpinet" /></button>
                                <button onClick={() => handleClick('ru-RU')}><img src={lng_ru} alt="Arpinet" /></button>
                                <button onClick={() => handleClick('am-AM')}><img src={lng_hy} alt="Arpinet" /></button>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </>
    )
}
