import React from 'react';

import hy_lg_internet from '../../assets/images/slider/hy_lg_internet.jpg'
import ru_lg_internet from '../../assets/images/slider/ru_lg_internet.jpg'
import en_lg_internet from '../../assets/images/slider/en_lg_internet.jpg'
import hy_sm_internet from '../../assets/images/slider/hy_sm_internet.jpg'
import ru_sm_internet from '../../assets/images/slider/ru_sm_internet.jpg'
import en_sm_internet from '../../assets/images/slider/en_sm_internet.jpg'
import hy_lg_internet_tv from '../../assets/images/slider/hy_lg_internet_tv.jpg'
import ru_lg_internet_tv from '../../assets/images/slider/ru_lg_internet_tv.jpg'
import en_lg_internet_tv from '../../assets/images/slider/en_lg_internet_tv.jpg'
import hy_sm_internet_tv from '../../assets/images/slider/hy_sm_internet_tv.jpg'
import ru_sm_internet_tv from '../../assets/images/slider/ru_sm_internet_tv.jpg'
import en_sm_internet_tv from '../../assets/images/slider/en_sm_internet_tv.jpg'
import hy_sm_voip from '../../assets/images/slider/hy_sm_voip.jpg'
import ru_sm_voip from '../../assets/images/slider/ru_sm_voip.jpg'
import en_sm_voip from '../../assets/images/slider/en_sm_voip.jpg'
import hy_lg_voip from '../../assets/images/slider/hy_lg_voip.jpg'
import ru_lg_voip from '../../assets/images/slider/ru_lg_voip.jpg'
import en_lg_voip from '../../assets/images/slider/en_lg_voip.jpg'
import hy_lg_free from '../../assets/images/slider/hy_lg_free.jpg'
import ru_lg_free from '../../assets/images/slider/ru_lg_free.jpg'
import en_lg_free from '../../assets/images/slider/en_lg_free.jpg'
import hy_sm_free from '../../assets/images/slider/hy_sm_free.jpg'
import ru_sm_free from '../../assets/images/slider/ru_sm_free.jpg'
import en_sm_free from '../../assets/images/slider/en_sm_free.jpg'
import hy_lg_invite_a_friend from '../../assets/images/slider/hy_lg_invite_a_friend.jpg'
import ru_lg_invite_a_friend from '../../assets/images/slider/ru_lg_invite_a_friend.jpg'
import en_lg_invite_a_friend from '../../assets/images/slider/en_lg_invite_a_friend.jpg'
import hy_sm_invite_a_friend from '../../assets/images/slider/hy_sm_invite_a_friend.jpg'
import ru_sm_invite_a_friend from '../../assets/images/slider/ru_sm_invite_a_friend.jpg'
import en_sm_invite_a_friend from '../../assets/images/slider/en_sm_invite_a_friend.jpg'


import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade} from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";


export default function HomeSlider() {
    const lng = localStorage.getItem('i18nextLng')
    return (
        <div className="col-12 p-0">
            <div className="container p-0">
                <Swiper
                    effect={"fade"}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{ delay: 6500, disableOnInteraction: false}}
                    pagination={{clickable: true, el: ".main-slider__pagination"}}
                    modules={[Pagination, EffectFade, Autoplay]}
                >

                <SwiperSlide>
                    <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"} 
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_internet_tv : hy_lg_internet_tv) :en_lg_internet_tv 
                            } />
                    <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_internet_tv : hy_sm_internet_tv) :en_sm_internet_tv 
                            } /> 
                </SwiperSlide>
                <SwiperSlide>
                    <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"} 
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_internet : hy_lg_internet) :en_lg_internet 
                            } />
                    <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_internet : hy_sm_internet) :en_sm_internet 
                            } /> 
                </SwiperSlide>
                <SwiperSlide>
                    <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"} 
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_voip : hy_lg_voip) :en_lg_voip 
                            } />
                    <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_voip : hy_sm_voip) :en_sm_voip 
                            } /> 
                </SwiperSlide>
                {/* <SwiperSlide>
                    <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"} 
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_invite_a_friend : hy_lg_invite_a_friend) :en_lg_invite_a_friend 
                            } />
                    <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_invite_a_friend : hy_sm_invite_a_friend) :en_sm_invite_a_friend 
                            } /> 
                </SwiperSlide> */}
                <SwiperSlide>
                    <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"} 
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_free : hy_lg_free) :en_lg_free 
                            } />
                    <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_free : hy_sm_free) :en_sm_free 
                            } /> 
                </SwiperSlide>     
           
      </Swiper>
      <div className="main-slider__pagination"></div>
    </div>
    <div className="sep-line mt-2"></div>
</div>

    )
}

