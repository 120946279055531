import { Link } from 'react-router-dom';

export default function CardIcons(props) {
  return (
    <div className="col-lg-4 col-md-6 col-6 card-icons stretch-card">
      {props?.external != null ?
        <a href={props.external} className='card w-100 b-radius b-shadow border-0 p-3 py-4 justify-content-center my-2 stretch-card'>
          <div className="row align-items-center">
            <div className="col-md-3 col-12 fs-1 m-0 d-flex justify-content-center orange-text">{props.icon}</div>
            <div className="col-md-9 col-12 ">
              <div className='fs-4 fw-bold text-center text-md-start mt-md-0 mt-4 text-uppercase gradient-green-bg gradient-text m-0'>{props.headline}</div>
              <div className='gradient-green-bg text-center text-md-start gradient-text'>{props.subhead}</div>
            </div>
          </div>
        </a>
        :
        <Link to={props.link} className='card w-100 b-radius b-shadow border-0 p-3 py-4 justify-content-center my-2 stretch-card'>
          <div className="row align-items-center">
            <div className="col-md-3 col-12 fs-1 m-0 d-flex justify-content-center orange-text">{props.icon}</div>
            <div className="col-md-9 col-12 ">
              <div className='fs-4 fw-bold text-center text-md-start mt-md-0 mt-4 text-uppercase gradient-green-bg gradient-text m-0'>{props.headline}</div>
              <div className='gradient-green-bg text-center text-md-start  gradient-text'>{props.subhead}</div>
            </div>
          </div>
        </Link>
      }
    </div>
  )
}
