import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { baseURL, postsURL } from '../static/api';
import { Link } from 'react-router-dom';
import { FiCalendar, FiArrowRight, FiArrowLeft } from 'react-icons/fi';
import MetaTags from './ui-elements/MetaTags';

export default function AllPosts() {
    const lng = localStorage.getItem('i18nextLng');
    const { t } = useTranslation();

    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchPosts = (page = 1) => {
        setLoading(true);
        axios
            .get(`${baseURL + postsURL}?page=${page}`, { headers: { "Accept-Language": lng.substring(0, 2) } })
            .then((res) => {
                setPosts(res.data.results);
                setTotalPages(Math.ceil(res.data.count / 10)); // Assuming 10 items per page
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        fetchPosts(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <MetaTags title={t('News')} />
            <div className="col-12 pt-5 pb-5 p-0">
                <div className="container">
                    {loading && <p>{t('Loading...')}</p>}
                    {!loading && (
                        <>
                            <div className="row">
                                {posts.map((post) => (
                                    <Link
                                        to={`/news/${post.id}`}
                                        className="col-sm-12 card b-radius b-shadow p-0 mb-4 news-item overflow-hidden border-0"
                                        key={post.id}
                                    >
                                        <div className="row p-0 m-0">
                                            <div className="col-lg-3 col-md-3 col-sm-5 col-xs-12 p-0 post-image-wrapper">
                                                <div
                                                    className="post-image"
                                                    style={{ backgroundImage: `url(${post.image})` }}
                                                ></div>
                                            </div>
                                            <div className="col-md-9 col-sm-7 col-xs-12 p-3">
                                                <div className="text-uppercase h5 fw-bold gradient-green-bg gradient-text">
                                                    {post.title.length > 65
                                                        ? `${post.title.substring(0, 65)}...`
                                                        : post.title}
                                                </div>
                                                <div className="text-muted my-2 d-flex align-items-center">
                                                    <FiCalendar className="me-2" />
                                                    {post.date}
                                                </div>
                                                <div className="text-muted text">
                                                    {post.description.length > 200
                                                        ? `${post.description.substring(0, 200)}...`
                                                        : post.description}
                                                </div>
                                                <div className="text-uppercase green-text d-flex justify-content-end align-items-center">
                                                    {t('_continue')} <FiArrowRight className="ms-2" />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <nav className="mt-4">
  <ul className="pagination justify-content-center">
    {currentPage > 1 && (
      <li className="page-item">
        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
          <FiArrowLeft/>
        </button>
      </li>
    )}
    {currentPage > 2 && (
      <li className="page-item">
        <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
      </li>
    )}
    {currentPage > 3 && <li className="page-item disabled"><span className="page-link">...</span></li>}
    {[...Array(totalPages)].slice(
      Math.max(0, currentPage - 2),
      Math.min(totalPages, currentPage + 1)
    ).map((_, index) => {
      const page = Math.max(1, currentPage - 1) + index;
      return (
        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
        </li>
      );
    })}
    {currentPage < totalPages - 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
    {currentPage < totalPages - 1 && (
      <li className="page-item">
        <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
      </li>
    )}
    {currentPage < totalPages && (
      <li className="page-item">
        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
          <FiArrowRight/>
        </button>
      </li>
    )}
  </ul>
</nav>

                        </>
                    )}
                </div>
            </div>
        </>
    );
}
