import React from 'react'
import { Link } from 'react-router-dom';
import UserRate from './UserRate';
import { FiCoffee, FiArchive, FiAtSign, FiSmartphone, FiUser } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

export default function UserSideBar(props) {
  const user_rated = localStorage.getItem('r')
  const { t } = useTranslation();
  return (

    <div className="card w-100 b-radius b-shadow border-0 p-4 ">
    <span className=''>{t('_user_page_sidebar_section_welcome')}</span>
    <span className='fw-bold'>{props?.user}</span>
    <hr/>
    <div className='col-12 p-0 text-start'>
       <div className='d-flex justify-content-between align-items-center py-2'>
        <span className='align-items-center d-flex'><FiUser className='me-3 small'/>{t('_user_page_sidebar_section_subscriber')}</span>
        <span className='fw-bold'>{props?.uid}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center py-2'>
      <span className='align-items-center d-flex'><FiSmartphone className='me-3 small'/>{t('_user_page_sidebar_section_mobile_number')}</span>
        <span className='fw-bold'>{props?.mobile}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center py-2'>
      <span className='align-items-center d-flex'><FiAtSign className='me-3 small'/>{t('_user_page_sidebar_section_e_mail')}</span>
        {props?.email == null ?
          <span className='fw-bold text-danger'> {t('_user_page_sidebar_section_no_e_mail')}</span> :
          <span className='fw-bold'>{props?.email}</span>
        }
      </div>
      <div className='d-flex justify-content-between align-items-center py-2'>
      <span className='align-items-center d-flex'><FiArchive className='me-3 small'/> {t('_user_page_sidebar_section_contracts')}</span>
        <span className='fw-bold'>{props?.agreement} {t('_user_page_sidebar_section_count')}</span>
      </div>
      {/* <div className='d-flex justify-content-between align-items-center py-2'>
      <span className='align-items-center d-flex'><FiArchive className='me-3 small'/> {t('_user_page_sidebar_section_cards')}</span>
        <span className='fw-bold'>{props?.agreement}</span>
      </div> */}
      <div className='d-flex justify-content-between align-items-center py-2'>
        <Link to={'feedback'} className="text-dark opacity-75 align-items-center d-flex">
        <FiCoffee className='me-3 small'/> {t('_user_page_sidebar_section_feedback')}
        </Link>
       </div>
      <hr/>

        {user_rated ? null :
        <>
        <hr/>
        <div className='d-flex justify-content-center align-items-center '>
          <UserRate/>
        </div>
        </>
        }
    </div>
</div>

  )
}
