import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiCalendar, FiArrowRight } from 'react-icons/fi';

export default function PostsComponent(props) {
    const { t } = useTranslation();
  return (
    <div>
        {props.posts.slice(0, 5).map((posts, i) =>
            <Link key={i} to={`/news/${posts.id}`} className="col-sm-12 card b-radius b-shadow p-0 mb-4 news-item overflow-hidden border-0">
                <div className="row">
                    {posts.category === '2' ? null :
                        <div className="col-lg-3 col-md-3 col-sm-5 col-xs-12 p-0 post-image-wrapper">
                            <div className='post-image' style={{ backgroundImage: `url(${posts.image})` }} ></div>

                            {/* <img className='post-image' src={posts.image} alt={posts.title}/> */}
                        </div>
                    }
                    <div className=
                        {posts.category === '2' ?
                            "col-xs-12 p-4 " :
                            "col-lg-9 col-md-9 col-sm-7 col-xs-12 py-4 px-5 "
                        }>
                        <div className="text-uppercase h5 fw-bold gradient-green-bg gradient-text" href={`/news/${posts.id}`}>
                            {posts.title.length > 65 ? `${posts.title.substring(0, 65)}...` : posts.title}
                        </div>

                        <div className="small text-muted my-2 d-flex align-items-ceter"><FiCalendar className='me-2'/>{posts.date}</div>

                        <div className="text-muted text">{posts.description.length > 200 ? `${posts.description.substring(0, 200)}...` : posts.description}</div>

                        <div className="text-uppercase green-text d-flex justify-content-end align-items-center" href={`/news/${posts.id}`}>
                        {t('_continue')} <FiArrowRight className='ms-2 small'/>
                        </div>
                    </div>
                </div>
            </Link>
        )}
    </div>
  )
}