import { FiCheck } from 'react-icons/fi';
import { voipIncludes, voipExtra, voipTarif } from '../../static/json'
import { useTranslation } from 'react-i18next';
import MetaTags from '../ui-elements/MetaTags';
import en_lg_voip from '../../assets/images/slider/en_lg_voip.jpg'
import hy_lg_voip from '../../assets/images/slider/hy_lg_voip.jpg'
import ru_lg_voip from '../../assets/images/slider/ru_lg_voip.jpg'
import en_sm_voip from '../../assets/images/slider/en_sm_voip.jpg'
import hy_sm_voip from '../../assets/images/slider/hy_sm_voip.jpg'
import ru_sm_voip from '../../assets/images/slider/ru_sm_voip.jpg'
import { Link } from 'react-router-dom';


export default function VoipService() {

  const lng = localStorage.getItem('i18nextLng')

  const { t } = useTranslation();

  return (
    <>
      <MetaTags
        title={'Թվային հեռախոսակապ'}
        desc={'ArpiCam տեսահսկման նոր ծառայությունը հեղաշրջում է կատարում այս ոլորտում՝ ազատելով Ձեզ բոլոր ծախսերից և քաշքշուքներից։'}
      />

      <div className="col-12 p-0">
        <div className="container pb-0">
          <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"}
            src={
              lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_voip : hy_lg_voip) : en_lg_voip
            } />
          <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
            src={
              lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_voip : hy_sm_voip) : en_sm_voip
            } />
        </div>
        <div className="sep-line mt-2"></div>
      </div>

      <div className="col-xs-12 p-0 pt-5 pb-2 pricing">
        <div className="container pt-3 pb-2">
          <div className="col-12 pt-2 pb-4">
            <div className="row">

              {voipTarif.map((tarif, i) =>
                <div className="col-sm-4 p-2 mb-4 stretch-card" key={tarif.id}>
                  <Link to={''} href="#" className="card w-100 b-radius b-shadow internet border-0 m-2">
                    <h5>{tarif.name}</h5>
                    <p className="small text-muted text-center w-100">{t(tarif.subname)}</p>
                    <h3>{tarif.price}</h3>
                    <div className="inet-info col-12 pt-3">
                      {tarif.include ? (
                        <>
                          <h1>{tarif.include}</h1>
                          <ul className="till-wrapper pt-3 text-muted">
                            <li>{t('_tariff_minutes')} </li>
                            <li>{t('_tariff_monthly')}</li>
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </Link>
                </div>
              )}
            </div>

            <div className="col-12 pt-2 pb-4 p-0">
              <div className="row">
                <div className="col-lg-6 col-sm-12 stretch-card">
                  <div className="card w-100 b-radius b-shadow border-0 mt-2 p-4">
                    {voipIncludes.map((inc, i) =>
                      <div key={i} className='d-flex justify-content-between align-items-center my-2'>
                        <span className='fw-nomal'>{t(inc.desc)}</span>
                        <span className='fw-bold'>{inc.price} {t(inc.currency)}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 stretch-card">
                  <div className="card w-100 b-radius b-shadow border-0 mt-2 p-4">
                    {voipExtra.map((ex, i) =>
                      <div key={i} className='d-flex justify-content-between align-items-center my-2'>
                        <span className='fw-nomal'>{t(ex.desc)}</span>
                        <span className='fw-bold'>{ex.price} {t(ex.currency)}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sep-line"></div>
      </div>

      <div className="col-xs-12 p-0 pt-4 pb-5 text text-justify">
        <div className="container pt-2 pb-5">
          <span className="gradient-green-bg gradient-text font-weight-bolder"> {t('_voip_acs')} </span>{" "} {t('_voip_description_line_1')}
          <p className="pt-2 pb-3"> {t('_voip_description_line_2')}</p>

          <div className="col-sm-12 p-0 text-left">
            <div className="row">
              <div className="col-md-6 col-xs-12 pr-lg-5">
                <p><FiCheck className="me-3" /> {t('_voip_acs_01')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_02')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_03')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_04')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_05')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_06')}</p>
              </div>
              <div className="col-md-6 col-xs-12">
                <p><FiCheck className="me-3" /> {t('_voip_acs_07')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_08')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_09')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_10')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_11')}</p>
                <p><FiCheck className="me-3" /> {t('_voip_acs_12')}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
